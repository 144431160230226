var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "advisor-form"
  }, [_c('div', {
    staticClass: "advisor-form__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "outline gn-secondary",
    attrs: {
      "arrowIconLeft": true
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Go back")])], 1), _c('div', {
    staticClass: "advisor-form__box"
  }, [_c('div', {
    staticClass: "advisor-form__content"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('autenti-form', {
    ref: "autentiForm",
    attrs: {
      "hasAutenti": _vm.hasAutenti,
      "mode": "global",
      "showSaveButton": !_vm.isMarketplaceAccountant && _vm.hasAutenti
    },
    on: {
      "formUpdated": function formUpdated($event) {
        _vm.hasChanges = true;
      }
    }
  })], 1)])]), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLeavingModal,
      expression: "showLeavingModal"
    }],
    staticClass: "leaving-modal",
    attrs: {
      "isLoading": _vm.isLeavingModalLoading,
      "header": "Do you want to save the changes?",
      "description": "It looks like there are some changes made in your template. What do you want to do?"
    },
    on: {
      "cancel": _vm.changeRoute,
      "confirm": _vm.confirmSavingTemplate,
      "close": function close($event) {
        _vm.showLeavingModal = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }